@import './variable.scss';

.map-container {
  display: flex;
  justify-content: center;

  &__map {
    height: 60vh;
    width: 100%;
  }
}

.properties-map-container {
  display: flex;
  justify-content: center;

  &__map {
    height: 80vh;
    width: 100%;
  }

  .map-card {
    &-image {
      width: 100%;

      img {
        width: 100%;
      }
    }

    &-info {
      padding: 1rem;
    }
  }

  .gm-style .gm-style-iw-c {
    padding: 0 !important;
    border-radius: 0 !important;
  }

  .gm-style-iw-d {
    overflow: hidden !important;
  }

  .gm-ui-hover-effect {
    display: none !important;
  }
}

.office-map-container {
  &__map {
    height: 45vh;
    width: 100%;
  }
}

.add-property-map-container {
  display: flex;
  justify-content: center;

  &__map {
    height: 40vh;
    width: 100%;
  }
}

.marker-cluster-small {
  background-color: #000 !important;
}
.marker-cluster-small div {
  background-color: #000 !important;
}
.marker-cluster-medium {
  background-color: #000 !important;
}
.marker-cluster-medium div {
  background-color: #000 !important;
}

.marker-cluster-large {
  background-color: #000 !important;
}
.marker-cluster-large div {
  background-color: #000 !important;
}
