/* opernlayers map */
@import "/node_modules/ol/ol.css";

.ol-map {
  height: 60vh;
  width: 100%;
  position: relative;
}

.ol-attribution.ol-uncollapsible {
  height: 1.4em;
  line-height: unset;
  display: block;
}

.ol-attribution.ol-uncollapsible li a {
  text-decoration: none;
  color: #333;
  font-size: 13px;
}

.ol-attribution {
  display: none;
}

.ol-map:fullscreen {
  height: 100%;
}

/* ol-map popup */
.ol-popup {
  /* position: absolute; */
  background-color: white;
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 400px;
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
  cursor: pointer;
}

.ol-popup-closer:after {
  content: "✖";
}
