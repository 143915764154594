@import './variable.scss';

@media print {
  footer {
    page-break-after: always;
  }
}
@page {
  size: auto;
  margin: 12.7mm;
}

.sale-receipt-print {
  // padding-top: 2mm;
  width: 100%;
}

.receipt-header-print {
  width: 50%;

  .receipt-header {
    height: 29mm;
    // background-color: green;
  }
}

.receipt-header-print-line {
  width: 12.7mm;
  margin-left: 12.7mm;
}

.print-line {
  width: 12.7mm;
  border-left: 1px dashed #212529;
  margin-left: 12.7mm;
}

.print-label {
  font-size: 0.65rem;

  &-xs {
    font-size: 0.55rem;
  }
}

.print-receipt-table {
  tr {
    height: 1rem;
  }

  th,
  td {
    font-size: 0.6rem;
    border-color: #f58357;
    vertical-align: middle;
  }

  th {
    font-size: 0.6rem;
    font-weight: bold;
  }
}

.border-black {
  border-bottom: 1px solid #212529;
}

.print-labels {
  display: flex;
  flex-wrap: wrap;

  .print-card {
    width: 45mm;
    height: 15mm;
    border: 1px dashed rgb(122, 122, 122);
    display: flex;
    align-items: center;
    padding: 9px;

    .card-label {
      font-size: 0.5rem;
      margin-bottom: 3px;
      font-weight: bold;
    }
  }
}
.voutcher-logo{
  // width: 100% !important;
   height: 9rem;
   object-fit: cover;

  }
  .receipt-summary{
    width: 30% !important;
  }
  .modal-voucher-size{
    height: 5rem !important;
  }