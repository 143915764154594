@import './variable.scss';


.flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
}
.f-col{
  display: flex;
  flex-direction: column;
  // justify-content: start;
}
.flex-start{
  display: flex;
  justify-content: start;
}
.cursor{
  cursor: pointer;
}
.bg-green{
  background-color: #EAF3FF;
  border-radius: 5px;
}
.bg-title{
  background-color: #EAF3FF;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
}
a{
  text-decoration: none;
}
.text-primary{
  color: $primary !important;
}
.title{
  font-size: 40px !important;
}
.flex-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.flex-column{
  display: flex;
flex-direction: column;
align-items: center;
// justify-content: center;
}
.search-input {
  input {
    height: 2.5rem;
  }
}
.bg-primary{
  background-color: $primary !important;
  color: white !important;
}
.bg-grad{
  background-color: $primary !important;
  color: white;
}
.bg-gray-white {
  background-color: transparent !important;
  border: 1px solid white !important;
  &:hover {
    background-color: $primary !important;
    color: white !important;
    border: 1px solid $primary !important;

  }

}
.bg-card {
  background-color: white !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  // padding: 1.5rem;
  border-radius: 5px;
  // height: 64vh;
  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    transition: box-shadow 400ms;
  }
}

.bg-card-no-hover {
  background-color: white !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  // padding: 1.5rem;
  border-radius: 5px;
}

.bg-info-card {
  background-color: white !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  // padding: 1.5rem;
  border-radius: 5px;
  // height: 64vh;
  &:hover {
    background-color: #FFF2CC !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    transition: box-shadow 400ms;
  }
}
.info-card {
  background-color: white !important;
  border-radius: 5px;
  height: auto;

  &:hover {
    transition: box-shadow 300ms;
  }
}
.schedule-card{
  background-color: white !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  // padding: 1.5rem;
  border-radius: 5px;
}
.btn:focus{
  box-shadow: none !important;
}
.bg-secondary{
  background-color: #FFF2CC !important;
}

.btn-blue{
  background-color: transparent !important;
  color: #002299 !important;
  font-weight: 200;
  border: 1px solid #002299!important;
  &:hover {
    background-color: transparent !important;
    color: #002299 !important;
    font-weight: 600;
    border: 1px solid #002299!important;

  }

}
.form-label{
  color:#002299 ;
}
.accordion-item{
  border: none !important;
  color: black !important;
}
.accordion-header{
 color: black !important;
}
.accordion-button:not(.collapsed) {
  color: black !important;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  line-height: 1.8 !important;
}
.swiper-pagination{
  display: none !important;
}
.text-blue{
  color: #002299;
}
.lh-2{
  line-height: 2;
}
.blue{
  color: #3D37F1;
}
.btn-primary {
    color: #fff;
  background-color: #002299;
    border-color: #002299;
    // border-radius: 0 !important;
  }
  .btn-primary:hover {
    color: #fff;
    background-color: #002299;
    border-color: #002299;
    border-radius: none !important;
  }
.btn-yel {
    color: #fff;
  background-color: $primary;
    border-color: $primary;
    border-radius: 5px !important;
    // &:active{
    //   color: #fff;
    //   background-color: $primary;
    //     border-color: $primary;
    // }
  }
  
  .btn-yel:hover {
    color: #fff;
    background-color: $primary;
    border-color: $primary;
    border-radius: 5px !important;

  }
  .btn-yel:focus {
    color: #fff;
    background-color: $primary;
    border-color: $primary;
    border-radius: 5px !important;

  }
.btn-yel-right {
    color: #fff;
  background-color: $primary;
    border-color: $primary;
    
    // &:active{
    //   color: #fff;
    //   background-color: $primary;
    //     border-color: $primary;
    // }
  }
  
  .btn-yel-right:hover {
    color: #fff;
    background-color: $primary;
    border-color: $primary;

  }
  .btn-yel-right:focus {
    color: #fff;
    background-color: $primary;
    border-color: $primary;
  }
.btn-yel-left {
    color: #fff;
  background-color: $primary;
    border-color: $primary;
  }
  
  .btn-yel-left:hover {
    color: #fff;
    background-color: $primary;
    border-color: $primary;
  }
  .btn-yel-left:focus {
    color: #fff;
    background-color: $primary;
    border-color: $primary;
  }
  //header
  .header{
    // position: sticky !important;
    z-index: 10000000 !important;
    @media (max-width:770px){
      display: none !important;
    }
  }
  .nav-link{
    color: black !important;
  }
  .header-nav-link{
      
    &.active {
      font-weight: bold;
      color: #002299  !important;
    }
      &::after {
        width: 100%;
      }}
  .header-nav-links{
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width:770px){
      display: none;
    }
  }
 
  .sm-top-nav{
    display: none;
    @media (max-width:770px){
      // background-color: #FFF2CC;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .banner{
    width: 100%;
    height: 80vh;
  
    img{
      object-fit: cover !important;
      width: 100%;
      height: 100%;
    }
  }
  .banner-title{
    position: relative;
  }
  .btn-gp{
    position: relative;
  }
.text-gray{
  position: relative;
  color: #EFEFEF !important;
}
  .text-yel{
    position: relative;
    color: #CC9933;
    line-height: 4rem;
  }

  @media (max-width:435px){
    .banner{
      width: 100%;
      height: 50vh !important;
      .banner{
        position: relative; 
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
       &::before{
        content: "";
          background-image: url('../../../public/images/banner.png');
          background-size: cover;
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
          opacity: 0.85;
      }
      }
      .banner-title{
        position: relative;
      }
      .btn-gp{
        position: relative;
      }
    .text-gray{
      position: relative;
      color: #EFEFEF;
    }
      .text-yel{
        position: relative;
        color: #CC9933;
        line-height: 4rem;
    
      }
      .text-yel{
        color: #CC9933;
        line-height: 2rem;
    
      }}
  }
  //welcome cpn
  .img-right{
    width: 100%;
    // height: 100vh;
  
    img{
      object-fit: cover !important;
      width: 100%;
      // height: 100%;
    }
  }
  .info-img{
    width: auto;
    height: 200px;
  }
  .course-img{
    width: 100%;
    height: 200px;
    img{
      object-fit: cover !important;
      width: 100%;
      // height: 100%;
  }
  }
  .event-img{
      width: 100%;
      height: 40vh;
      // height: auto;
      img{
        object-fit: cover !important;
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        height: 100%;
    }
  }
  .detail-img{
      width: 100%;
      // height: 40vh;
      height: 50vh;
      img{
        object-fit: cover !important;
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        height: 100%;
    }
  }
  .course-img{
      width: 100%;
      height: 40vh;
      height: auto;
      img{
        object-fit: cover !important;
        width: 100%;
        // height: 100%;
    }
  }
  //courses
  
  .product-img {
    position: relative;
    overflow: hidden;
    background-color:#B6990F ;
    border-radius: 5px;
    // border-top-right-radius: 5px;

    img {
      width: 100%;
      opacity: 0.7;
    
    }
    &-badges {
      position: absolute;
      top: 12px;
      right: 12px;
      span {
        font-size: 13px;
        font-weight: 500;
        line-height: 1;
        display: block;
        margin-bottom: 10px;
        padding: 3px 11px;
        color: #fff;
        border-radius: 3px;
        &.pink {
          background-color: $primary;
        }
        &.purple {
          background-color: $secondary;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }}
    .course-right{
      float: left;
      // top: 12px;
      // right: 12px;
      bottom: 20px;
      // width: 50%;
      position: absolute;
      z-index: 2;
    }
  //scrol to top btn
  .scroll-to-top {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    animation: fadeIn 700ms ease-in-out 1s both;
    cursor: pointer;
    z-index: 100000;
  }
  // upload img
  .image-area {
    position: relative;
    display: inline-block;
    margin-bottom: 0.5rem;
    // margin-right: 1rem;
    width: 150px;
    height: 150px;
    box-sizing: border-box;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.125);
    // border-radius:12px !important ;
  }
  .image-area img {
    display: block;
    width: auto;
    height: 100%;
    margin: 0 auto;
  
  }
  
  .remove-image {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 2%;
    height: 24px;
    text-decoration: none;
    background-color: $primary;
    color: #fff;
    transition: background 0.1s;
    display: inline;
    border: 0;
    border-radius: 0;
    // border-bottom-left-radius: 10px;
  }
  
  .remove-image svg {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(5);
  }
  
  .remove-image:hover,
  .remove-image:active,
  .remove-image:focus {
    background-color: #dc3545;
    box-shadow: none;
  }




  //footer
  .footer-links{
    color: white !important;
  }
  .bg-footer{
    background-color: #112734 !important;
  }
  .swiper-button-next, .swiper-button-prev,.swiper-rtl .swiper-button-prev{
    background-color: #CC9933 !important;
    padding: 18px !important;
    color: white !important;
    border-radius: 3px !important;
    
  }
  .swiper-button-next::after{
    font-size: 20px !important;
  }
  .swiper-button-prev::after{
    font-size: 20px !important;
  }
  .bg-black{
    background-color: black;
  }
  //table
  //Table
.table > :not(:first-child) {
  border-top: 1px solid $success;
}

// .table tr {
//   height: 3.5rem;
// }

th{
  color: #002299 !important;

}
th,
td {
  font-size: 0.9rem;
  vertical-align: middle;
  background-color: $primary;
}

th {
  background-color: $primary;
  font-weight: bold;
}
.photo-box{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ced4da;
  width: 150px;
  // height: 100%;
  padding: 30px;
  margin-top: 10px;
  height: 150px;
}
//contact

//   form
/* contact */
.contact {
  .box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px 0;
    &:hover {
      .icon {
        background: $primary;
    border: 1px solid $primary;

        svg {
          color: #fff;
        }
      }
    }
    a {
      text-decoration: none;
      color: $primary;
    }
  }
  h5 {
    margin-bottom: 5px;
  }
  .form-control-lg {
    font-size: 1rem;
  }
 
  .icon {
    font-size: 20px;
    color: gray;
    margin-right: 30px;
    border: 1px solid gray;
    border-radius: 50%;
    svg {
      margin: 15px;
    }
  }
}
.send {
  .send-btn {
    background-color: $primary !important;
  }
}
.bg-content {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
}
.office-map-container__map {
  height: 45vh;
  width: 100%;
}

.office-map-container {
  &__map {
    height: 45vh;
    width: 100%;
  }
}
//pagination
.page-link{
  color: $primary !important;
  border: 1px solid $primary !important;
}
.page-item.active .page-link {
  background-color: $primary !important;
  color: white !important;
}
.text-ylw{
  color: $primary !important;
}
.underlined{
  text-decoration:  underline !important;
}
.w-3{
  width:50px;
  background-color: #e7f1ff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: white;
}
.d-box{
  height: 230px !important;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: black !important;
}
:not(.btn-check) + .btn:hover, .btn:first-child:hover {
 
  border-color: $primary !important; 
}

.hover{
  color: blue;
  &:hover{
    text-decoration:underline !important;
  }
}
.map-height{
  height: 100vh !important;
}